var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title mb-0 pt-50"},[_vm._v(" Add Request")])]),_c('b-col',{attrs:{"cols":"12","md":"6 ml-auto"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" Back ")])],1)])],1),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',{staticClass:"mt-1"},[(_vm.requestTypes.length > 1)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":"Request Type","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Request Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.requestTypes,"reduce":function (item) { return item.type; },"clearable":false,"placeholder":"Select"},on:{"input":function($event){return _vm.onRequestTypeChanged()}},model:{value:(_vm.addRequest.type),callback:function ($$v) {_vm.$set(_vm.addRequest, "type", $$v)},expression:"addRequest.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,861665967)})],1)],1):_vm._e(),(_vm.addRequest.type != null && _vm.types.length > 1)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":"Type","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.types,"label":"parent_type","reduce":function (item) { return item; },"clearable":false,"placeholder":"Select","disabled":_vm.addRequest.type == null},on:{"input":function($event){return _vm.onTypeChanged()}},model:{value:(_vm.parent_type),callback:function ($$v) {_vm.parent_type=$$v},expression:"parent_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1589339136)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":"Request Name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Request Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.requestName,"reduce":function (item) { return item; },"clearable":false,"placeholder":"Select","disabled":_vm.addRequest.type == null},on:{"input":function($event){return _vm.onRequestNameChangedChanged()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":"Request Details","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Request Details","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"2","disabled":_vm.addRequest.type == null,"state":errors.length > 0 ? false:null},model:{value:(_vm.addRequest.description),callback:function ($$v) {_vm.$set(_vm.addRequest, "description", $$v)},expression:"addRequest.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.addRequest.type !== null && _vm.show_list)?_c('b-row',[(_vm.addRequest.type === 'timesheet' && _vm.addRequest.parent_type_id !== 3)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":"Consultant","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Consultant","rules":_vm.addRequest.type !== 'job' && _vm.show_list ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.employees,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select","disabled":_vm.addRequest.type == null},on:{"change":function($event){return _vm.onEmployeeChanged($event)}},model:{value:(_vm.employee_id),callback:function ($$v) {_vm.employee_id=$$v},expression:"employee_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2526101595)})],1)],1):_vm._e()],1):_vm._e(),(_vm.addRequest.type !== null && _vm.show_list)?_c('b-row',[(_vm.addRequest.type !== 'job')?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":"Period","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Period","rules":_vm.addRequest.type !== 'job' && _vm.show_list ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.monthConfig,"placeholder":"MM-YYYY","disabled":_vm.addRequest.type == null},on:{"on-change":_vm.monthChanged},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,367695010)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"text-right",attrs:{"label":_vm.list_title,"label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.list_title,"rules":_vm.show_list ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.items,"reduce":function (item) { return item.id; },"clearable":false,"placeholder":"Select","disabled":_vm.addRequest.type == null},model:{value:(_vm.addRequest.type_id),callback:function ($$v) {_vm.$set(_vm.addRequest, "type_id", $$v)},expression:"addRequest.type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,306879998)})],1)],1)],1):_vm._e(),_c('hr'),_c('b-row',{staticClass:"ml-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"button"},on:{"click":_vm.submitForm}},[_vm._v(" Add Request ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }