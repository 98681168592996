<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Request</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mt-1">
          <b-col
            v-if="requestTypes.length > 1"
            cols="6"
          >
            <b-form-group
              label="Request Type"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Request Type"
                rules="required"
              >
                <v-select
                  v-model="addRequest.type"
                  :options="requestTypes"
                  :reduce="item => item.type"
                  :clearable="false"
                  placeholder="Select"
                  @input="onRequestTypeChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="addRequest.type != null && types.length > 1"
            cols="6"
          >
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  v-model="parent_type"
                  :options="types"
                  label="parent_type"
                  :reduce="item => item"
                  :clearable="false"
                  placeholder="Select"
                  :disabled="addRequest.type == null"
                  @input="onTypeChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Request Name"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Request Name"
                rules="required"
              >
                <v-select
                  v-model="name"
                  :options="requestName"
                  :reduce="item => item"
                  :clearable="false"
                  placeholder="Select"
                  :disabled="addRequest.type == null"
                  @input="onRequestNameChangedChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Request Details"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Request Details"
                rules="required"
              >
                <b-form-textarea
                  v-model="addRequest.description"
                  rows="2"
                  :disabled="addRequest.type == null"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="addRequest.type !== null && show_list">
          <b-col
            v-if="addRequest.type === 'timesheet' && addRequest.parent_type_id !== 3"
            cols="6"
          >
            <b-form-group
              label="Consultant"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Consultant"
                :rules="addRequest.type !== 'job' && show_list ? 'required' : ''"
              >
                <v-select
                  v-model="employee_id"
                  :options="employees"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                  :disabled="addRequest.type == null"
                  @change="onEmployeeChanged($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="addRequest.type !== null && show_list">
          <b-col
            v-if="addRequest.type !== 'job'"
            cols="6"
          >
            <b-form-group
              label="Period"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Period"
                :rules="addRequest.type !== 'job' && show_list ? 'required' : ''"
              >
                <flat-pickr
                  v-model="month"
                  class="form-control"
                  :config="$store.state.monthConfig"
                  placeholder="MM-YYYY"
                  :disabled="addRequest.type == null"
                  @on-change="monthChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="list_title"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                :name="list_title"
                :rules="show_list ? 'required' : ''"
              >
                <v-select
                  v-model="addRequest.type_id"
                  :options="items"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                  :disabled="addRequest.type == null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Request
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BFormTextarea,
} from 'bootstrap-vue'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import User from '@/js/user'
import Invoices from '@/js/invoices'
import Timesheets from '@/js/timesheets'
import Jobs from '@/js/jobs'
import Requests from '@/js/requests'
import LIST from '@/js/lists'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      name: null,
      addRequest: {
        type: null,
        name: '',
        parent_id: null,
        parent_type_id: null,
        type_id: null,
        invoice: '',
        description: '',
      },
      month: null,
      employerName: '',
      employee_id: null,
      type: null,
      type_id: null,
      list_title: '',
      show_list: false,
      parent_type: null,
      employees: [],
      companies: [],
      items: [],
      types: [],
      requestTypes: [],
      requestName: [],
      correctedInvoices: [],
    }
  },
  mounted() {
  },
  created() {
    let isTimesheetExist = false
    let isJobExist = false
    let isInvoiceExist = false
    if (User.profile().timesheet_provider_parents.length > 0) {
      isTimesheetExist = true
    }
    if (User.profile().invoice_parents.length > 0) {
      isInvoiceExist = true
    }
    if (User.profile().job_parent.length > 0) {
      isJobExist = true
    }
    Constants.getRequestTypes(this, isTimesheetExist, isInvoiceExist, isJobExist)
    this.addRequest.created_date = Constants.getEprmMonthDateFromString(moment().format('MM-DD-YYYY'))
    if (this.requestTypes.length === 1) {
      this.addRequest.type = this.requestTypes[0].type
      this.onRequestTypeChanged()
    }
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.showLoading = true
          Requests.addRequest(this)
        }
      })
    },
    setTimeSheets(timesheets) {
      this.items = timesheets
    },
    setCorrectedInvoices(invoices) {
      this.items = invoices
    },
    setTaskNames(names) {
      this.requestName = names
    },
    setJobs(jobs) {
      this.items = jobs
    },
    setStatus(status) {
      this.status = status
    },
    setVendors(vendors) {
      this.vendors = vendors
    },
    setEmployees(employees) {
      this.employees = employees
    },
    setEmployerTimeSheets(timeSheets) {
      this.timeSheets = timeSheets
    },
    onRequestNameChangedChanged() {
      this.addRequest.name = this.name.label
      if (this.name.label.toLowerCase().includes(this.addRequest.type.toLowerCase())) {
        this.show_list = true
      } else {
        this.show_list = false
      }
    },
    onRequestTypeChanged() {
      this.parent_type = null
      this.name = null
      this.employee_id = null
      this.items = []
      this.employees = []
      this.list_title = ''
      this.addRequest.name = ''
      this.addRequest.type_id = null
      if (this.addRequest.type !== null) {
        if (this.addRequest.type === 'timesheet') {
          this.types = User.profile().timesheet_provider_parents
          this.list_title = 'Timesheets'
          LIST.getTaskNameFullList(this, 'FA', 'TS')
        } else if (this.addRequest.type === 'invoice') {
          this.types = User.profile().invoice_parents
          this.list_title = 'Invoices'
          this.requestName = []
        } else if (this.addRequest.type === 'job') {
          this.types = User.profile().job_parent
          LIST.getTaskNameFullList(this, 'HR', 'JO')
          this.list_title = 'Jobs'
        }
        if (this.types.length === 1 && this.addRequest.type !== 'job') {
          this.addRequest.parent_id = this.types[0].parent_id
          this.addRequest.parent_type_id = this.types[0].parent_type_id
          this.parent_type = this.types[0].parent_type
          if (this.addRequest.type === 'invoice') {
            if (this.types[0].parent_type.toLowerCase() === 'subvendor') {
              LIST.getTaskNameFullList(this, 'FA', 'IS')
              if (this.month !== null) {
                Invoices.getSubVendorCorrectedInvoices(this, this.types[0].parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
              }
            } else if (this.types[0].parent_type.toLowerCase() === 'suppliers') {
              LIST.getTaskNameFullList(this, 'FA', 'IP')
              if (this.month !== null) {
                Invoices.getSupplierCorrectedInvoices(this, this.types[0].parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
              }
            } else if (this.types[0].parent_type.toLowerCase() === 'referrer') {
              LIST.getTaskNameFullList(this, 'FA', 'IR')
              if (this.month !== null) {
                Invoices.getReferrerCorrectedInvoices(this, this.types[0].parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
              }
            } else {
              LIST.getTaskNameFullList(this, 'FA', 'IE')
              if (this.month !== null) {
                Invoices.getEmployerCorrectedInvoices(this, this.types[0].parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
              }
            }
          } else if (this.addRequest.type === 'timesheet') {
            if (this.types[0].parent_type.toLowerCase() === 'subvendor') {
              LIST.getSubvendorTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
            } else if (this.types[0].parent_type.toLowerCase() === 'suppliers') {
              LIST.getSupplierTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
            } else if (this.types[0].parent_type.toLowerCase() === 'referrer') {
              LIST.getReferrerTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
            } else if (this.types[0].parent_type.toLowerCase() === 'employer') {
              LIST.getEmployerTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
            } else if (this.types[0].parent_type.toLowerCase() === 'employee') {
              this.employee_id = this.types[0].parent_id
              this.onEmployeeChanged()
            }
          }
        } else if (this.addRequest.type === 'job') {
          Jobs.getRequest(this)
          if (this.types.length === 1) {
            this.addRequest.parent_id = this.types[0].parent_id
            this.addRequest.parent_type_id = this.types[0].parent_type_id
          }
        }
      }
    },
    monthChanged(config, month) {
      this.month = month
      this.items = []
      this.addRequest.type_id = null
      if (this.addRequest.type === 'timesheet') {
        if (this.month != null && this.employee_id !== null) {
          Timesheets.getRequestTimesheets(this, this.employee_id, '01', moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
        }
      } else if (this.addRequest.type === 'invoice' && this.month !== null && this.addRequest.parent_id !== null) {
        if (this.parent_type.toLowerCase() === 'subvendor') {
          Invoices.getSubVendorCorrectedInvoices(this, this.addRequest.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
        } else if (this.parent_type.toLowerCase() === 'suppliers') {
          Invoices.getSupplierCorrectedInvoices(this, this.addRequest.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
        } else if (this.parent_type.toLowerCase() === 'referrer') {
          Invoices.getReferrerCorrectedInvoices(this, this.addRequest.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
        } else {
          Invoices.getEmployerCorrectedInvoices(this, this.addRequest.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
        }
      }
    },
    onTypeChanged() {
      this.employee_id = null
      this.employees = []
      this.addRequest.parent_id = null
      this.addRequest.parent_type_id = null
      this.items = []
      this.addRequest.type_id = null
      if (this.parent_type.parent_id !== null) {
        this.addRequest.parent_id = this.parent_type.parent_id
        this.addRequest.parent_type_id = this.parent_type.parent_type_id
        if (this.addRequest.type === 'invoice') {
          if (this.parent_type.parent_type.toLowerCase() === 'subvendor') {
            LIST.getTaskNameFullList(this, 'FA', 'IS')
            if (this.month !== null) {
              Invoices.getSubVendorCorrectedInvoices(this, this.parent_type.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
            }
          } else if (this.parent_type.parent_type.toLowerCase() === 'suppliers') {
            LIST.getTaskNameFullList(this, 'FA', 'IP')
            if (this.month !== null) {
              Invoices.getSupplierCorrectedInvoices(this, this.parent_type.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
            }
          } else if (this.parent_type.parent_type.toLowerCase() === 'referrer') {
            LIST.getTaskNameFullList(this, 'FA', 'IR')
            if (this.month !== null) {
              Invoices.getReferrerCorrectedInvoices(this, this.parent_type.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
            }
          } else {
            LIST.getTaskNameFullList(this, 'FA', 'IE')
            if (this.month !== null) {
              Invoices.getEmployerCorrectedInvoices(this, this.parent_type.parent_id, moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
            }
          }
        } else if (this.addRequest.type === 'timesheet') {
          if (this.parent_type.parent_type.toLowerCase() === 'subvendor') {
            LIST.getSubvendorTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
          } else if (this.parent_type.parent_type.toLowerCase() === 'suppliers') {
            LIST.getSupplierTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
          } else if (this.parent_type.parent_type.toLowerCase() === 'referrer') {
            LIST.getReferrerTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
          } else if (this.parent_type.parent_type.toLowerCase() === 'employer') {
            LIST.getEmployerTimeSheetEmployeeConsultantFullList(this, this.addRequest.parent_id)
          } else if (this.parent_type.parent_type.toLowerCase() === 'employee') {
            this.employee_id = this.parent_type.parent_id
            this.onEmployeeChanged()
          }
        } else if (this.addRequest.type === 'job') {
          Jobs.getRequest(this)
        }
      }
    },
    onEmployeeChanged() {
      this.items = []
      if (this.addRequest.type === 'timesheet') {
        if (this.month !== null && this.employee_id !== null) {
          Timesheets.getRequestTimesheets(this, this.employee_id, '01', moment(this.month, 'MMM-YYYY').format('MM'), moment(this.month, 'MMM-YYYY').format('YYYY'))
        }
      } else if (this.addRequest.type === 'invoice') {
        this.types = User.profile().invoice_parents
        this.list_title = 'Invoices'
      }
    },
    requestAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    requestNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/requests').catch(() => {})
    },
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
